import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { provide } from '../../../common/utils/ioc/provide';
import { GameContentStore } from '../../stores/game-content-store';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { useNavigate, useParams } from 'react-router-dom';
import { BetSlipStore } from '../../../bet-slip/stores/bet-slip-store';
import { LoadStatus } from '../../../common/enums/load-status';
import { GameContentActions } from './game-content-actions';
import { Leaderboard } from '../../../leaderboard/components/leaderboard';
import { BetHistory } from '../bet-history';
import { CommonBettingSection } from '../../../redesigned-modules/common/components/common-betting-section/common-betting-section';
import { GameMatchContent } from './game-match-content';
import { SportLeagues } from '../../../redesigned-modules/common/components/sport-leagues/sport-leagues';
import { Chip, Stack, Typography, useTheme } from '@mui/material';
import { formatDateWithName } from '../../../../utils';
import { AppContentContainer } from '../../../redesigned-modules/common/components/app-content-container/app-content-container';
import { BetSlip } from '../../../bet-slip/redesigned-components/bet-slip';
import { LiveOddsStore } from '../../../common/stores/live-odds-store';

export const GameContentPage = provide([
    BetSlipStore,
    GameContentStore,
    LiveOddsStore,
])(
    observer(() => {
        const [
            {
                sportLeagueId,
                sportLeaguesLoadStatus,
                init,
                selectSportLeague,
                selectMatch,
                sports,
                isShowingLeaderboard,
                hideLeaderboard,
                game,
                isShowingBetHistory,
                hideBetHistory,
            },
            { initBetSlip, setMinBetAmount },
        ] = useDependencies(GameContentStore, BetSlipStore);

        const {
            gameId: gameIdParam,
            leagueId: leagueIdParam,
            matchId: matchIdParam,
        } = useParams();

        useEffect(() => {
            if (gameIdParam) {
                const gameId = +gameIdParam;

                initBetSlip(gameId);
                init(gameId);
            }
        }, []);

        useEffect(() => {
            if (game) {
                setMinBetAmount(game.minBetAmount);
            }
        }, [game, setMinBetAmount]);

        useEffect(() => {
            // TODO: add number validation
            if (leagueIdParam) {
                selectSportLeague(+leagueIdParam);
            }
        }, [leagueIdParam]);

        useEffect(() => {
            // TODO: add number validation
            selectMatch(matchIdParam ? +matchIdParam : undefined);
        }, [matchIdParam]);

        const navigate = useNavigate();

        const handleSportLeagueChange = (
            selectedId: number,
            replace?: boolean
        ) => {
            if (selectedId !== sportLeagueId) {
                navigate(`../${selectedId}`, { replace });
            }
        };

        useEffect(() => {
            if (
                sportLeaguesLoadStatus === LoadStatus.Ok &&
                leagueIdParam === undefined
            ) {
                const firstValidSport = sports.find(
                    (sport) => !!sport.leagues?.length
                );

                if (firstValidSport) {
                    const firstLeague = firstValidSport.leagues![0];
                    handleSportLeagueChange(firstLeague.id, true);
                }
            }
        }, [sportLeaguesLoadStatus]);

        const theme = useTheme();

        const handleBetSlipMatchClick = (matchId: number) => {
            navigate(`../${sportLeagueId}/${matchId}`);
        };

        return (
            <AppContentContainer>
                <CommonBettingSection
                    actions={<GameContentActions />}
                    betSlip={<BetSlip onMatchClick={handleBetSlipMatchClick} />}
                    content={
                        <GameMatchContent
                            activeMatchId={
                                matchIdParam ? +matchIdParam : undefined
                            }
                        />
                    }
                    sportLeagues={
                        <SportLeagues
                            sportLeagues={sports}
                            activeSportLeagueId={sportLeagueId}
                            onChange={handleSportLeagueChange}
                        />
                    }
                    header={
                        game ? (
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                alignItems={{ xs: 'start', sm: 'center' }}
                                spacing={2}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontWeight: 900,
                                        color: theme.palette.primary.main,
                                    }}
                                >
                                    Game #{game.id}
                                </Typography>
                                <Chip
                                    label={`${formatDateWithName(game.startDate)} - ${formatDateWithName(game.endDate)}`}
                                />
                            </Stack>
                        ) : undefined
                    }
                />
                {isShowingLeaderboard && game && (
                    <Leaderboard gameId={game.id} onClose={hideLeaderboard} />
                )}
                {isShowingBetHistory && game && (
                    <BetHistory gameId={game.id} onClose={hideBetHistory} />
                )}
            </AppContentContainer>
        );
    })
);
