import { inject, injectable } from 'inversify';
import { makeAutoObservable, runInAction } from 'mobx';
import { BettingHubStore } from '../../root/stores/betting-hub-store';
import {
    MatchOddModel,
    OddCoeffUpdateHubModel,
    SubsectionUpdateHubModel,
} from '../api/api';

interface ILiveOddModel
    extends Pick<MatchOddModel, 'americanStyleOdd' | 'coefficient'> {}

@injectable()
export class LiveOddsStore {
    @inject(BettingHubStore) private readonly bettingHubStore!: BettingHubStore;

    liveOdds = new Map<MatchOddModel['id'], ILiveOddModel>();

    constructor() {
        makeAutoObservable(this);
    }

    listenToLiveOdds = () => {
        this.bettingHubStore.connection.on(
            'UpdateOddCoefficientAsync',
            (data: OddCoeffUpdateHubModel) => {
                if (this.liveOdds.has(data.id)) {
                    this.saveLiveOdd(data.id, data);
                }
            }
        );
    };

    registerLiveOdd = (id: MatchOddModel['id'], odd: ILiveOddModel) => {
        if (!this.liveOdds.has(id)) {
            this.saveLiveOdd(id, odd);
        }
    };

    private saveLiveOdd = (id: MatchOddModel['id'], odd: ILiveOddModel) => {
        runInAction(() => {
            this.liveOdds.set(id, {
                americanStyleOdd: odd.americanStyleOdd,
                coefficient: odd.coefficient,
            });
        });
    };
}
