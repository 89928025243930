import { observer } from 'mobx-react';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { PlayoffStore } from '../../../stores/playoff-store';
import { PlayoffGame } from './playoff-game';
import { PlayoffSection } from './playoff-section';
import { PlayoffBracketImage } from './images/playoff-bracket-image';
import Box from '@mui/material/Box';

export const PlayoffBracket = observer(() => {
    const [{ gameLeaguePlayoffData }] = useDependencies(PlayoffStore);

    return (
        <PlayoffSection
            title={'Playoff Bracket'}
            justifyGamesContent={'space-around'}
            absoluteImage={
                <Box
                    sx={{
                        position: 'absolute',
                        top: 160,
                        right: 0,
                        zIndex: -1,
                    }}
                >
                    <PlayoffBracketImage />
                </Box>
            }
        >
            {(roundNumber) =>
                gameLeaguePlayoffData?.playoffBracket
                    .find((item) => item.roundNumber === roundNumber)
                    ?.games.map((game, _, array) => {
                        return (
                            <PlayoffGame
                                isFinal={array.length === 1}
                                game={game}
                            />
                        );
                    })
            }
        </PlayoffSection>
    );
});
