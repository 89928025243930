import { PlayoffGameModel } from '../../../../common/api/api';
import { FC, Fragment } from 'react';
import { Avatar, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import {
    customNeutral,
    customSecondary,
    customTertiary,
} from '../../../../redesigned-modules/root/components/theme-provider/colors';
import Typography from '@mui/material/Typography';
import { ColoredBorder } from '../../../../common/components/colored-border/colored-border';
import { DiamondsFour } from '@phosphor-icons/react';
import Box from '@mui/material/Box';
import PlayoffWinnerBackground from '../../../../../assets/redesign/images/playoff-winner-background.png';
import { getOrdinalSuffix } from '../../../../common/utils/get-ordinal-suffix';
import Tooltip from '@mui/material/Tooltip';

interface IPlayoffGameProps {
    game: PlayoffGameModel;
    isFinal?: boolean;
}

export const PlayoffGame: FC<IPlayoffGameProps> = ({ game, isFinal }) => {
    return (
        <Box
            sx={{
                overflow: 'visible',
                position: 'relative',
            }}
        >
            {isFinal && (
                <Typography
                    fontSize={20}
                    color={customSecondary[100]}
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        textAlign: 'center',
                        transform: 'translateY(-120%)',
                    }}
                    fontWeight={600}
                >
                    Winner
                </Typography>
            )}
            <Card
                sx={{
                    height: 106,
                    width: '100%',
                    background: 'rgba(24, 40, 49, 0.5)',
                    border: `1px solid ${customTertiary['650']}`,
                }}
            >
                {game.players.map(
                    ({ playerInfo, regularSeasonResult, rank }, index) => {
                        const isWinner = rank === 1 && isFinal;

                        return (
                            <Fragment>
                                <Box
                                    sx={{
                                        p: 1.5,
                                        pt: index === 1 ? 1 : undefined,
                                        pb: index === 0 ? 1 : undefined,
                                        backgroundImage: isWinner
                                            ? `url(${PlayoffWinnerBackground})`
                                            : undefined,
                                        backgroundSize: 'cover',
                                    }}
                                >
                                    <Stack
                                        sx={{
                                            height: 32,
                                        }}
                                        direction={'row'}
                                        spacing={1}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                    >
                                        <Typography
                                            color={
                                                isWinner
                                                    ? customNeutral[750]
                                                    : 'white'
                                            }
                                            fontSize={12}
                                            sx={{ minWidth: 24 }}
                                        >
                                            #{rank}
                                        </Typography>
                                        <ColoredBorder
                                            toTop
                                            borderSize={'2px'}
                                            borderRadius={100}
                                        >
                                            <Avatar
                                                sx={{ width: 28, height: 28 }}
                                                src={playerInfo.avatarUrl}
                                            />
                                        </ColoredBorder>
                                        <Stack
                                            sx={{
                                                flex: '1 1 auto',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Tooltip
                                                placement={'top'}
                                                title={playerInfo.username}
                                            >
                                                <Typography
                                                    color={
                                                        isWinner
                                                            ? customNeutral[950]
                                                            : 'white'
                                                    }
                                                    fontSize={16}
                                                    lineHeight={1}
                                                    textOverflow={'ellipsis'}
                                                    overflow={'hidden'}
                                                    whiteSpace={'nowrap'}
                                                    width={'fit-content'}
                                                    maxWidth={'100%'}
                                                >
                                                    {playerInfo.username}
                                                </Typography>
                                            </Tooltip>
                                            <Typography
                                                fontSize={12}
                                                color={
                                                    isWinner
                                                        ? customNeutral[800]
                                                        : customNeutral[200]
                                                }
                                            >
                                                ({regularSeasonResult.winCount}-
                                                {regularSeasonResult.loseCount}-
                                                {regularSeasonResult.tieCount},{' '}
                                                {getOrdinalSuffix(
                                                    regularSeasonResult.regularSeasonRank
                                                )}
                                                )
                                            </Typography>
                                        </Stack>
                                        <Box sx={{ alignSelf: 'start' }}>
                                            <DiamondsFour
                                                weight={'fill'}
                                                size={16}
                                            />
                                        </Box>
                                    </Stack>
                                </Box>
                            </Fragment>
                        );
                    }
                )}
            </Card>
        </Box>
    );
};
