import { observer } from 'mobx-react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Login } from '../../../../auth/components/login/login';
import { Signup } from '../../../../auth/components/signup/signup';
import Forgot from '../../../../../pages/Auth/Forgot';
import Reset from '../../../../../pages/Auth/Reset';
import Contact from '../../../../../pages/Contact';

export const AppUnauthenticated = observer(() => {
    const location = useLocation();

    const returnURL = location.pathname + location.search + location.hash;

    const redirectionURLSearchParams = new URLSearchParams();
    redirectionURLSearchParams.set('returnURL', encodeURIComponent(returnURL));

    return (
        <Routes>
            {/*common*/}
            <Route path="contacts" element={<Contact />} />

            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forgot-password" element={<Forgot />} />
            <Route path="password-reset" element={<Reset />} />
            <Route
                path="*"
                element={
                    <Navigate
                        to={{
                            pathname: 'login',
                            search: redirectionURLSearchParams.toString(),
                        }}
                        replace
                    />
                }
            />
        </Routes>
    );
});
