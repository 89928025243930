import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { CurrentLeagueGameStore } from '../../../stores/current-league-game-store';
import { useNavigate } from 'react-router-dom';
import { MatchWithOddsModel } from '../../../../common/api/api';
import { MatchContent } from '../../../../common/components/match-content/match-content';

interface ICurrentGameContentProps {
    activeMatchId?: number;
}

export const CurrentGameContent: FC<ICurrentGameContentProps> = observer(
    ({ activeMatchId }) => {
        const [
            {
                matchOverviewsLoadStatus,
                matchOverviews,
                matchInfo,
                matchSectionId,
                matchSections,
                matchOddsLoadStatus,
                selectOddSection,
                oddSubsectionGroups,
                sportLeagueId,
            },
        ] = useDependencies(CurrentLeagueGameStore);

        const navigate = useNavigate();

        const handleMatchClick = (matchOverview: MatchWithOddsModel) => {
            navigate(`${matchOverview.match.id}`);
        };

        const handleBackToMatches = () => navigate(`../${sportLeagueId}`);

        return (
            <MatchContent
                match={matchInfo}
                matchSections={matchSections}
                activeMatchId={activeMatchId}
                matchOverviews={matchOverviews}
                onMatchClick={handleMatchClick}
                matchSectionId={matchSectionId}
                onSelectSection={selectOddSection}
                onBackToMatches={handleBackToMatches}
                matchOddsLoadStatus={matchOddsLoadStatus}
                oddSubsectionGroups={oddSubsectionGroups}
                matchOverviewsLoadStatus={matchOverviewsLoadStatus}
            />
        );
    }
);
