import { observer } from 'mobx-react-lite';
import Dialog from '@mui/material/Dialog';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { useIsMobile } from '../../common/hooks/use-is-mobile';
import DialogContent from '@mui/material/DialogContent';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { AddFriendsStore } from '../stores/add-friends-store';
import { FriendsContentStore } from '../stores/friends-content-store';
import { provide } from '../../common/utils/ioc/provide';
import Grid from '@mui/material/Grid';
import { Player } from './player';
import { AsyncList } from '../../common/components/async-table-list/async-list';
import { DebouncedSearch } from '../../common/components/debounced-search/debounced-search';

export const AddFriends = provide([AddFriendsStore])(
    observer(() => {
        const [
            {
                search,
                players,
                fetchPlayers,
                loadStatus,
                addFriend,
                acceptFriendRequest,
                denyFriendRequest,
            },
            { stopAddingFriends },
        ] = useDependencies(AddFriendsStore, FriendsContentStore);

        const isMobile = useIsMobile();

        return (
            <Dialog
                maxWidth="md"
                fullScreen={isMobile}
                open={true}
                onClose={stopAddingFriends}
                fullWidth
            >
                <Box
                    display="flex"
                    justifyContent={'center'}
                    alignItems={'center'}
                    px={2}
                    sx={{ height: 68 }}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        <Typography
                            fontWeight={900}
                            fontSize={24}
                            color={'primary'}
                        >
                            Add Friends
                        </Typography>
                    </DialogTitle>
                    <IconButton
                        onClick={stopAddingFriends}
                        sx={{ p: 0, position: 'absolute', right: 22 }}
                    >
                        <CloseIcon sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Box>
                <DialogContent sx={{ px: 1, pb: 2 }}>
                    <DebouncedSearch
                        sx={{ mb: 3 }}
                        onSearch={search}
                        fullWidth
                    />
                    <AsyncList
                        length={players.length}
                        loadStatus={loadStatus}
                        onFetch={fetchPlayers}
                        prefetch={true}
                    >
                        {(setLastElement) => (
                            <Grid container spacing={1}>
                                {players.map((player, index) => (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        key={player.id}
                                        ref={
                                            index === players.length - 1
                                                ? setLastElement
                                                : undefined
                                        }
                                    >
                                        <Player
                                            player={player}
                                            onAdd={addFriend}
                                            onAccept={acceptFriendRequest}
                                            onDeny={denyFriendRequest}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </AsyncList>
                </DialogContent>
            </Dialog>
        );
    })
);
