import { observer } from 'mobx-react';
import FooterBackground from '../../../../../assets/redesign/images/footer-background.png';
import { Box } from '@mui/system';
import { RedesignedThemeProvider } from '../theme-provider/theme-provider';
import { BalanceBetLogo } from '../logo/balance-bet-logo';
import { Stack } from '@mui/material';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { DiscordIcon } from './icons/discord';
import { InstagramIcon } from './icons/instagram';
import { XIcon } from './icons/x';
import { useScreenSize } from '../../../../common/hooks/use-is-mobile';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { AppStore } from '../../../../common/stores/app-store';
import { useEffect, useRef } from 'react';

const FooterMain = observer(() => {
    const [{ isMobileBetSlipVisible, setFooterHeight }] =
        useDependencies(AppStore);

    const { isMobile } = useScreenSize();

    const footerElRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (!footerElRef.current) {
            return;
        }

        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                setFooterHeight(entry.borderBoxSize[0].blockSize);
            });
        });

        resizeObserver.observe(footerElRef.current);

        return () => resizeObserver.disconnect();
    }, [footerElRef.current]);

    return (
        <Box
            ref={footerElRef}
            component={'footer'}
            sx={{
                background: `url(${FooterBackground})`,
                backgroundPosition: 'top',
                pt: 4,
                px: {
                    md: '80px',
                },
            }}
        >
            <Box
                sx={{
                    borderTopRightRadius: 40,
                    borderTopLeftRadius: 40,
                    boxShadow: '0px 0px 32px 10px #FFFFFF26 inset',
                    backdropFilter: 'blur(40px)',
                    px: {
                        xs: 3,
                        md: 4,
                    },
                    py: 3,
                    mb: isMobileBetSlipVisible ? 10 : 0,
                }}
            >
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    alignItems={isMobile ? 'start' : 'center'}
                    justifyContent={'space-between'}
                    sx={{ mb: { xs: 4, sm: 1 } }}
                    spacing={4}
                >
                    <BalanceBetLogo />
                    <Stack
                        direction={isMobile ? 'column' : 'row'}
                        alignItems={'center'}
                        spacing={3}
                    >
                        <Link
                            target={'_blank'}
                            component={'a'}
                            href={'https://balancebet.com/privacy-policy'}
                            underline={'hover'}
                            color={'#FCFCFC'}
                        >
                            Rulebook
                        </Link>
                        <Link
                            underline={'hover'}
                            component={RouterLink}
                            to={'/contacts'}
                            color={'#FCFCFC'}
                        >
                            Feedback
                        </Link>
                        <Link
                            underline={'hover'}
                            component={RouterLink}
                            to={'/contacts'}
                            color={'#FCFCFC'}
                        >
                            Contact Us
                        </Link>
                    </Stack>
                </Stack>
                <Stack
                    direction={isMobile ? 'column-reverse' : 'row'}
                    alignItems={isMobile ? 'start' : 'center'}
                    justifyContent={'space-between'}
                    spacing={4}
                >
                    <Typography color={'white'}>
                        Copyright © {new Date().getFullYear()} Balance Bet -
                        All Rights Reserved.
                    </Typography>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        spacing={1.5}
                    >
                        <Link
                            target="_blank"
                            // href={'https://www.instagram.com/balancebet/'}
                        >
                            <DiscordIcon />
                        </Link>
                        <Link
                            target="_blank"
                            href={'https://www.instagram.com/balancebet/'}
                        >
                            <InstagramIcon />
                        </Link>
                        <Link
                            target="_blank"
                            href={'https://twitter.com/balancebett'}
                        >
                            <XIcon />
                        </Link>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    );
});

export const Footer = () => (
    <RedesignedThemeProvider>
        <FooterMain />
    </RedesignedThemeProvider>
);
