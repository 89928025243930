import { inject, injectable } from 'inversify';
import { apiClient } from '../api/api-client';
import {
    AddPaymentMethodModel,
    IAddPaymentMethodModel,
    IPlayerSubscriptionPlanEnumSubscriptionPurchaseModel,
    JwtAuthResult,
    PlayerFullInfoModel,
    PlayerSubscriptionPlanEnum,
    PlayerSubscriptionPlanEnumSubscriptionPurchaseModel,
    RefreshTokenReceiveModel,
} from '../api/api';
import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment/moment';
import { NotificationsStore } from './notifications-store';

@injectable()
export class AuthStore {
    @inject(NotificationsStore)
    private readonly notificationsStore!: NotificationsStore;

    me?: PlayerFullInfoModel;
    isAuthenticated = false;
    readyToRender = false;
    isExpired = false;
    isShowingLimitations = false;

    constructor() {
        makeAutoObservable(this);
    }

    private setAccessToken = (accessToken?: string) => {
        localStorage.setItem('token', accessToken ?? '');
    };

    private setRefreshToken = (token?: string) => {
        localStorage.setItem('refreshToken', token ?? '');
    };

    getLocalAccessToken = () => {
        return localStorage.getItem('token') ?? '';
    };

    authorize = async (jwtAuthResult?: JwtAuthResult) => {
        if (jwtAuthResult) {
            this.setAccessToken(jwtAuthResult.accessToken);

            if (jwtAuthResult.refreshToken) {
                this.setRefreshToken(jwtAuthResult.refreshToken.tokenString);
            }
        }

        runInAction(() => {
            this.readyToRender = false;
        });

        try {
            const me = await apiClient.me();

            const subscriptionEndDate = me.subscription.endDate;

            if (
                subscriptionEndDate &&
                subscriptionEndDate.getTime() <= Date.now()
            ) {
                this.setExpired();
            }

            runInAction(() => {
                this.me = me;
                this.isAuthenticated = true;
            });
        } catch {
            // skip
        }

        runInAction(() => {
            this.readyToRender = true;
        });
    };

    handleUnauthorized = async () => {
        this.setAccessToken('');

        const refreshToken = localStorage.getItem('refreshToken');

        if (refreshToken) {
            const {
                accessToken: newAccessToken,
                refreshToken: refreshTokenResult,
            } = await apiClient.refreshToken(
                new RefreshTokenReceiveModel({
                    tokenString: refreshToken,
                })
            );

            this.setAccessToken(newAccessToken);

            if (refreshTokenResult) {
                this.setRefreshToken(refreshTokenResult.tokenString);
            }
        } else {
            throw new Error('Refresh Token not found');
        }
    };

    unAuthorize = () => {
        this.me = undefined;
        this.isAuthenticated = false;
        this.setAccessToken('');
        this.setRefreshToken('');
    };

    get isTrial() {
        return this.me?.subscription.plan === PlayerSubscriptionPlanEnum.Trial;
    }

    get remainingSubscriptionDays() {
        if (!this.me) {
            return undefined;
        }

        const { endDate } = this.me.subscription;

        if (endDate) {
            return Math.max(0, moment(endDate).diff(new Date(), 'day') + 1);
        } else {
            return undefined;
        }
    }

    get hasSubscriptionRemainingDays() {
        return this.remainingSubscriptionDays !== undefined;
    }

    reactivateSubscription = async () => {
        try {
            await apiClient.status();

            this.authorize();

            this.notificationsStore.notify({
                severity: 'success',
                children: 'Subscription reactivated!',
            });
        } catch {
            // skip
        }
    };

    subscribe = async () => {
        try {
            const data: IPlayerSubscriptionPlanEnumSubscriptionPurchaseModel = {
                redirectUrl: window.location.href,
                plan: PlayerSubscriptionPlanEnum.Standard,
            };

            const { url } = await apiClient.subscriptionPOST(
                new PlayerSubscriptionPlanEnumSubscriptionPurchaseModel(data)
            );

            if (url) {
                window.open(url, '_self');
            }
        } catch {
            // skip
        }
    };

    updatePaymentMethod = async () => {
        try {
            const data: IAddPaymentMethodModel = {
                redirectUrl: window.location.href,
            };

            const { url } = await apiClient.method(
                new AddPaymentMethodModel(data)
            );

            if (url) {
                window.open(url, '_self');
            }
        } catch {
            // skip
        }
    };

    cancelSubscription = async () => {
        try {
            await apiClient.subscriptionDELETE();

            this.authorize();

            this.notificationsStore.notify({
                severity: 'error',
                children: 'Subscription Cancelled!',
            });
        } catch {
            //skip
        }
    };

    setExpired = () => {
        this.isExpired = true;
    };

    showLimitations = () => {
        this.isShowingLimitations = true;
    };

    hideLimitations = () => {
        this.isShowingLimitations = false;
    };

    handlePaymentRequired = () => {
        if (this.isTrial) {
            this.showLimitations();
        } else {
            this.setExpired();
        }
    };
}
