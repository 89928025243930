import { FC, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { SectionImageContainer } from '../section-image-container/section-image-container';

interface ISectionWithBackgroundProps extends PropsWithChildren {
    imgSrc: string;
    headline: string;
    description: string;
}

export const SectionWithBackground: FC<ISectionWithBackgroundProps> = ({
    children,
    headline,
    imgSrc,
    description,
}) => {
    return (
        <Box
            sx={{
                minHeight: '100%',
            }}
        >
            <Container
                maxWidth={'xl'}
                sx={{
                    py: {
                        xs: 2,
                        sm: 4,
                    },
                    px: {
                        sm: 15,
                    },
                }}
            >
                <Box sx={{ mb: 4 }}>
                    <SectionImageContainer
                        src={imgSrc}
                        description={description}
                        headline={headline}
                    />
                </Box>
                {children}
            </Container>
        </Box>
    );
};
