import { Stack, Typography, TypographyProps } from '@mui/material';
import { useOdometerNumber } from 'shared/src/hooks';
import Tooltip from '@mui/material/Tooltip';
import { formatBigNumber, isBigNumber } from 'shared/src/utils';
import React, { FC } from 'react';
import { BalanceIcon } from '../icons/balance-icon';

interface IBalanceProps extends TypographyProps {
    amount?: number;
    hasOdometer?: boolean;
}

export const Balance: FC<IBalanceProps> = ({
    amount = 0,
    hasOdometer,
    ...props
}) => {
    const odometerCurrentValue = useOdometerNumber(amount);
    const currentValue = hasOdometer ? odometerCurrentValue : amount;

    return (
        <Stack direction="row" alignItems={'center'} spacing={0.5}>
            <BalanceIcon />
            <Tooltip
                arrow
                title={
                    isBigNumber(currentValue)
                        ? currentValue.toLocaleString()
                        : ''
                }
                placement="top"
            >
                <Typography {...props}>
                    {formatBigNumber(currentValue)}
                </Typography>
            </Tooltip>
        </Stack>
    );
};
