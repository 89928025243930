import { FC } from 'react';
import { observer } from 'mobx-react';
import { InputAdornment, TextField } from '@mui/material';
import { BalanceIcon } from '../../common/components/icons/balance-icon';

interface ITotalStakeInputProps {
    onChange: (value: number) => void;
}

export const TotalStakeInput: FC<ITotalStakeInputProps> = observer(
    ({ onChange }) => {
        const handleKeyDown = (
            event: React.KeyboardEvent<HTMLInputElement>
        ) => {
            // Prevent "e", "E", "+", "-", and any non-numeric key except backspace and arrows
            if (
                ['e', 'E', '+', '-'].includes(event.key) ||
                (event.key.length === 1 && !/^\d$/.test(event.key))
            ) {
                event.preventDefault();
            }
        };

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            // Allow only digits in the input value
            const numericValue = event.target.value.replace(/\D/g, '');
            onChange(+numericValue);
        };
        return (
            <TextField
                autoComplete="off"
                slotProps={{
                    input: {
                        inputProps: {
                            inputMode: 'numeric',
                        },
                        size: 'small',
                        startAdornment: (
                            <InputAdornment position="start">
                                <BalanceIcon />
                            </InputAdornment>
                        ),
                        sx: { fontWeight: 600 },
                    },
                }}
                sx={{
                    color: 'red',
                    // fontWeight: '500',
                    width: 120,
                }}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                inputMode="numeric"
            />
        );
    }
);
