import 'reflect-metadata';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.css';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import { store } from './store/store';
import { Notifications } from './modules/common/components/notifications/notifications';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { App } from './modules/redesigned-modules/root/components/app/app';
import { RedesignedThemeProvider } from './modules/redesigned-modules/root/components/theme-provider/theme-provider';

ReactGA.initialize('G-HPCTZZZK5S');

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

declare global {
    interface Window {
        toastr: any;
    }
}

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <RedesignedThemeProvider>
                <CssBaseline />
                <Notifications>
                    <App />
                </Notifications>
            </RedesignedThemeProvider>
        </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
