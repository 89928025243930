import { useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Collapse, IconButton, Stack, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MissionsIcon } from './missions-icon';
import { CaretDown, CaretUp, DotOutline } from '@phosphor-icons/react';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { BetSlipStore } from '../../stores/bet-slip-store';
import { customNeutral } from '../../../redesigned-modules/root/components/theme-provider/colors';

export const Missions = observer(() => {
    const [isOpen, setIsOpen] = useState(false);
    const [{ missions }] = useDependencies(BetSlipStore);

    const theme = useTheme();

    return (
        <Box
            sx={{
                px: 2,
                py: 1,
                background: `linear-gradient(135deg, #181c54, #0e5458)`,
                borderTop: `1px dashed ${customNeutral[400]}`,
            }}
        >
            <Stack
                alignItems={'center'}
                justifyContent={'space-between'}
                direction={'row'}
            >
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <MissionsIcon />
                    <Typography
                        color={'white'}
                        fontSize={20}
                        fontWeight={500}
                        sx={{
                            textShadow: '-1px -1px 10px #8CF5A8',
                        }}
                    >
                        Missions ({missions.length})
                    </Typography>
                </Stack>
                {!!missions.length && (
                    <IconButton
                        sx={{
                            width: 40,
                            height: 40,
                        }}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        {isOpen ? <CaretUp /> : <CaretDown />}
                    </IconButton>
                )}
            </Stack>
            <Collapse in={isOpen}>
                <Stack spacing={0.5} sx={{ py: 1 }}>
                    {missions.map((mission, index) => (
                        <Stack
                            key={index}
                            direction={'row'}
                            alignItems={'center'}
                            sx={{ pl: 1 }}
                        >
                            <DotOutline
                                weight={'fill'}
                                size={24}
                                color={theme.palette.primary.main}
                            />
                            <Typography fontSize={14}>
                                {mission.mission}
                            </Typography>
                        </Stack>
                    ))}
                </Stack>
            </Collapse>
        </Box>
    );
});
