import Box from '@mui/material/Box';
import { FC, PropsWithChildren } from 'react';
import AppBackgroundImage from '../../../../../assets/redesign/images/app-background.png';

export const RootLayout: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                height: '100vh',
                ':before': {
                    content: `""`,
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: `url(${AppBackgroundImage})`,
                    backgroundSize: 'contain',
                    filter: 'blur(70px) opacity(0.2)',
                    zIndex: -1,
                },
            }}
        >
            {children}
        </Box>
    );
};
