import { FC, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import GilroyLight from '../../../../../fonts/Gilroy-Light.ttf';
import GilroyRegular from '../../../../../fonts/Gilroy-Regular.ttf';
import GilroyMedium from '../../../../../fonts/Gilroy-Medium.ttf';
import GilroyBold from '../../../../../fonts/Gilroy-Bold.ttf';
import GilroyExtraBold from '../../../../../fonts/Gilroy-ExtraBold.ttf';
import {
    customNeutral,
    customPrimary,
    customSecondary,
    customTertiary,
} from './colors';
import { useTheme } from '@mui/material';

declare module '@mui/material/styles' {
    interface Palette {
        tertiary: Palette['primary'];
    }

    interface PaletteOptions {
        tertiary: PaletteOptions['primary'];
    }
}

const themeOptions: ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024,
            lg: 1920,
            xl: 2000,
        },
    },
    palette: {
        mode: 'dark',
        primary: customPrimary,
        secondary: customSecondary,
        tertiary: customTertiary,
        error: {
            main: '#ff0000',
            light: '#fae0df',
        },
        success: {
            main: '#5BE734',
        },
        text: {
            primary: '#ffffff',
            secondary: customNeutral[200],
        },
        background: {
            default: '#0E1826',
            paper: '#151D2C',
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 8,
    },
    typography: {
        fontFamily: 'Gilroy, Arial',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: url(${GilroyLight}) format('ttf');
        }
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${GilroyRegular}) format('ttf');
        }
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: url(${GilroyMedium}) format('ttf');
        }
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: url(${GilroyBold}) format('ttf');
        }
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 900;
          src: url(${GilroyExtraBold}) format('ttf');
        }
      `,
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        // MuiAvatar: {
        //     styleOverrides: {
        //         colorDefault: {
        //             background: '#ed3ae5',
        //         },
        //     },
        // },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
            },
        },
    },
};

const theme = createTheme(themeOptions);

export const RedesignedThemeProvider: FC<PropsWithChildren> = observer(
    ({ children }) => {
        return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
    }
);

export const TempThemeProvider: FC<PropsWithChildren> = observer(
    ({ children }) => {
        const outerTheme = useTheme();

        return (
            <ThemeProvider
                theme={createTheme(outerTheme, {
                    palette: {
                        mode: 'dark',
                        tertiary: undefined,
                        text: { primary: customNeutral[500] },
                    },
                })}
            >
                {children}
            </ThemeProvider>
        );
    }
);
