import { inject, injectable } from 'inversify';
import { action, makeAutoObservable } from 'mobx';
import { BettingHubStore } from '../../root/stores/betting-hub-store';
import {
    MatchOddModel,
    OddCoeffUpdateHubModel,
    OddDisableModel,
} from '../api/api';

interface ILiveOddModel
    extends Pick<MatchOddModel, 'americanStyleOdd' | 'coefficient'> {}

@injectable()
export class LiveOddsStore {
    @inject(BettingHubStore) private readonly bettingHubStore!: BettingHubStore;

    liveOdds = new Map<MatchOddModel['id'], ILiveOddModel>();
    disabledOdds = new Set<MatchOddModel['id']>();

    constructor() {
        makeAutoObservable(this);
    }

    listenToLiveOdds = () => {
        this.bettingHubStore.connection.on(
            'UpdateOddCoefficientAsync',
            action((data: OddCoeffUpdateHubModel) => {
                if (this.liveOdds.has(data.id)) {
                    this.saveLiveOdd(data.id, data);

                    if (this.disabledOdds.has(data.id)) {
                        this.disabledOdds.delete(data.id);
                    }
                }
            })
        );

        this.bettingHubStore.connection.on(
            'DisableOddAsync',
            action((data: OddDisableModel) => {
                this.disabledOdds.add(data.id);
            })
        );
    };

    registerLiveOdd = (id: MatchOddModel['id'], odd: ILiveOddModel) => {
        if (!this.liveOdds.has(id)) {
            this.saveLiveOdd(id, odd);
        }
    };

    private saveLiveOdd = (id: MatchOddModel['id'], odd: ILiveOddModel) => {
        this.liveOdds.set(id, {
            americanStyleOdd: odd.americanStyleOdd,
            coefficient: odd.coefficient,
        });
    };
}
