import { inject, injectable } from 'inversify';
import {
    HubConnection,
    HubConnectionBuilder,
    LogLevel,
} from '@microsoft/signalr';
import { makeAutoObservable } from 'mobx';
import {
    GeneralAnnouncementMessageModelFocusNotificationMessageModel,
    RankedGameCreatedMessageModelFocusNotificationMessageModel,
    RankingSeasonChangedMessageModelFocusNotificationMessageModel,
    RankUpdatedMessageModelFocusNotificationMessageModel,
} from '../../common/api/api';
import { isTokenExpired } from '../../common/utils/is-token-expired';
import { AuthStore } from '../../common/stores/auth-store';

const sequentialMessages = ['ReceiveNotificationCountAsync'];

const mapEventsToConstructors: Record<string, { fromJS: (data: any) => any }> =
    {
        ReceiveGeneralAnnouncementAsync:
            GeneralAnnouncementMessageModelFocusNotificationMessageModel,
        RankedGameCreatedAsync:
            RankedGameCreatedMessageModelFocusNotificationMessageModel,
        ReceiveRankUpdateAsync:
            RankUpdatedMessageModelFocusNotificationMessageModel,
        RankingSeasonChangedAsync:
            RankingSeasonChangedMessageModelFocusNotificationMessageModel,
    };

@injectable()
export class CommsHubStore {
    @inject(AuthStore) private readonly authStore!: AuthStore;

    connection: HubConnection;

    constructor() {
        makeAutoObservable(this);

        const connection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_BASE_URL_V2}/hubs/CommsHub`, {
                accessTokenFactory: async () => {
                    let token = this.authStore.getLocalAccessToken();

                    if (isTokenExpired(token)) {
                        await this.authStore.handleUnauthorized();

                        token = this.authStore.getLocalAccessToken();
                    }

                    return token;
                },
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        const originalConnectionOn = connection.on.bind(connection);

        connection.on = (event, handler, ...connectionRestArgs) => {
            let lastTimestamp = 0;
            const overridable = sequentialMessages.includes(event);

            const newHandler: typeof handler = (
                data,
                rawDate: Date,
                ...handlerRestArgs
            ) => {
                let finalData = data;
                const date = new Date(rawDate);
                const receivedTimestamp = date.getTime();

                if (mapEventsToConstructors[event]) {
                    finalData =
                        mapEventsToConstructors[event].fromJS(finalData);
                }

                if (overridable && receivedTimestamp < lastTimestamp) {
                    return;
                }

                handler(finalData, date, ...handlerRestArgs);
                lastTimestamp = receivedTimestamp;
            };

            return originalConnectionOn(
                event,
                newHandler,
                ...connectionRestArgs
            );
        };

        this.connection = connection;
    }

    init = async () => {
        try {
            await this.connection.start();
        } catch {
            //
        }
    };

    dispose = async () => {
        try {
            await this.connection?.stop();
        } catch {
            //
        }
    };
}
