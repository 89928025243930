import { FC } from 'react';
import { observer } from 'mobx-react';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { GameContentStore } from '../../stores/game-content-store';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MatchWithOddsModel } from '../../../common/api/api';
import { MatchContent } from '../../../common/components/match-content/match-content';

interface IGameContentProps {
    activeMatchId?: number;
}

export const GameMatchContent: FC<IGameContentProps> = observer(
    ({ activeMatchId }) => {
        const [
            {
                matchOverviewsLoadStatus,
                matchOverviews,
                matchInfo,
                matchSectionId,
                matchSections,
                matchOddsLoadStatus,
                selectOddSection,
                oddSubsectionGroups,
                sportLeagueId,
            },
        ] = useDependencies(GameContentStore);

        const navigate = useNavigate();

        const handleMatchClick = (matchOverview: MatchWithOddsModel) => {
            navigate(`${matchOverview.match.id}`);
        };

        const handleBackToMatches = () => navigate(`../${sportLeagueId}`);

        return (
            <MatchContent
                match={matchInfo}
                matchSections={matchSections}
                activeMatchId={activeMatchId}
                matchOverviews={matchOverviews}
                onMatchClick={handleMatchClick}
                matchSectionId={matchSectionId}
                onSelectSection={selectOddSection}
                onBackToMatches={handleBackToMatches}
                matchOddsLoadStatus={matchOddsLoadStatus}
                oddSubsectionGroups={oddSubsectionGroups}
                matchOverviewsLoadStatus={matchOverviewsLoadStatus}
            />
        );
    }
);
