import { FC, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import { keyframes, styled } from '@mui/material';
import { customNegative } from '../../../redesigned-modules/root/components/theme-provider/colors';

interface IOddChangeIndicatorProps {
    value: number;
}

const blink = keyframes`
  0%, 40%, 80% { opacity: 0; }
  20%, 60%, 100% { opacity: 1; }
`;

export const OddChangeIndicator: FC<IOddChangeIndicatorProps> = ({ value }) => {
    const firstRenderRef = useRef(true);
    const prevValueRef = useRef(value);
    const [shownArrowDirection, setShownArrowDirection] = useState<
        'up' | 'down' | null
    >(null);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return;
        }

        const prevValue = prevValueRef.current;

        if (value !== prevValue) {
            setTimeout(() => {
                setShownArrowDirection(value > prevValue ? 'up' : 'down');
            }, 0);

            prevValueRef.current = value;
        }

        return () => {
            setShownArrowDirection(null);
        };
    }, [value]);

    const Caret = shownArrowDirection === 'up' ? CaretUp : CaretDown;
    const caretColor =
        shownArrowDirection === 'up' ? 'green' : customNegative.main;
    return (
        <Box>
            {shownArrowDirection !== null && (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    sx={{
                        animation: `${blink} 1s`,
                    }}
                >
                    <Caret color={caretColor} weight={'fill'} />
                </Box>
            )}
        </Box>
    );
};
