import { provide } from '../../../../common/utils/ioc/provide';
import { AuthStore } from '../../../../common/stores/auth-store';
import { AppStore } from '../../../../common/stores/app-store';
import { CommsHubStore } from '../../../../root/stores/comms-hub-store';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { useAuthInterceptors } from '../../../../common/hooks/use-auth-interceptors';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ConfirmProvider } from 'material-ui-confirm';
import { AppAuthenticated } from './app-authenticated';
import { AppUnauthenticated } from './app-unauthenticated';
import { RootLayout } from '../layout/root-layout';

export const App = provide([AuthStore, AppStore, CommsHubStore])(
    observer(() => {
        const [{ authorize, isAuthenticated, readyToRender }] =
            useDependencies(AuthStore);

        const location = useLocation();

        useAuthInterceptors();

        useEffect(() => {
            authorize();
        }, []);

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [location.pathname]);

        let content = (
            <Box
                height={'100%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <CircularProgress />
            </Box>
        );

        if (readyToRender) {
            content = isAuthenticated ? (
                <AppAuthenticated />
            ) : (
                <AppUnauthenticated />
            );
        }

        return (
            <ConfirmProvider>
                <RootLayout>{content}</RootLayout>
            </ConfirmProvider>
        );
    })
);
