import { FC, PropsWithChildren } from 'react';
import { Container } from '@mui/material';

export const AppContentContainer: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Container maxWidth={'xl'} sx={{ px: { xs: 2, sx: 3, md: 10 } }}>
            {children}
        </Container>
    );
};
