import { injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';

@injectable()
export class AppStore {
    isMobileBetSlipVisible = false;
    headerHeight = 0;
    footerHeight = 0;

    constructor() {
        makeAutoObservable(this);
    }

    setIsMobileBetSlipVisible = (isVisible: boolean) => {
        this.isMobileBetSlipVisible = isVisible;
    };

    setHeaderHeight = (height: number) => {
        this.headerHeight = height;
    };

    setFooterHeight = (height: number) => {
        this.footerHeight = height;
    };
}
