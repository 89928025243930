import { FC } from 'react';
import { MatchWithOddsModel } from '../../api/api';
import { formatDateWithName, setLocalTime } from '../../../../utils';
import { Box, Grid2 as Grid, Stack, Typography, useTheme } from '@mui/material';
import { MatchOverviewRow, MatchOverviewRowProps } from './match-overview-row';
import { BetSlipMatchInfo } from '../../../bet-slip/stores/bet-slip-store';
import { BetSlipMatchContext } from '../../contexts/bet-slip-match-context';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { CaretRight } from '@phosphor-icons/react';

export interface MatchOverviewProps {
    columnNames: string[];
    matchOverview: MatchWithOddsModel;
    onMatchClick: MatchOverviewRowProps['onMatchClick'];
}

export const MatchOverview: FC<MatchOverviewProps> = ({
    columnNames,
    matchOverview,
    onMatchClick,
}) => {
    const betSlipMatchInfo: BetSlipMatchInfo = {
        id: matchOverview.match.id,
        teams: matchOverview.match.teams!.map(({ name }) => name!),
    };

    const day = formatDateWithName(matchOverview.match.startDate, true);

    const theme = useTheme();

    return (
        <BetSlipMatchContext.Provider value={betSlipMatchInfo}>
            <Box>
                <Grid container spacing={0.5}>
                    <Grid size={5}></Grid>
                    <Grid
                        size={7}
                        container
                        spacing={0.5}
                        sx={{
                            '& .MuiTypography-root': {
                                fontWeight: 600,
                                color: theme.palette.text.secondary,
                                fontSize: 14,
                                textAlign: 'center',
                                wordBreak: 'break-word',
                            },
                        }}
                    >
                        {columnNames.map((columnName) => (
                            <Grid
                                size={Math.round(12 / columnNames.length)}
                                key={columnName}
                                alignContent={'end'}
                            >
                                <Typography>{columnName}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1.5 }} />
                {matchOverview.odds.rows?.length === 1 ? (
                    <MatchOverviewRow
                        onMatchClick={onMatchClick}
                        matchOverview={matchOverview}
                        currentOdds={matchOverview.odds.rows![0].odds!}
                    />
                ) : (
                    <Box display={'flex'} flexDirection={'column'} gap={0.5}>
                        {matchOverview.match.teams?.map((team, index) => {
                            return (
                                <MatchOverviewRow
                                    onMatchClick={onMatchClick}
                                    key={team.id}
                                    matchOverview={matchOverview}
                                    currentTeam={team}
                                    currentOdds={
                                        matchOverview.odds.rows![index].odds!
                                    }
                                />
                            );
                        })}
                    </Box>
                )}
                <Grid container spacing={0.5} sx={{ mt: 0.5 }}>
                    <Grid size={{ xs: 12, md: 5 }}>
                        <Stack
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                        >
                            <Typography fontSize={12} color={'textSecondary'}>
                                {day}{' '}
                                {setLocalTime(matchOverview.match.startDate)}
                            </Typography>
                            <Button
                                onClick={() => onMatchClick(matchOverview)}
                                color={'inherit'}
                                sx={{
                                    color: theme.palette.text.secondary,
                                    fontWeight: 600,
                                }}
                                endIcon={<CaretRight size={16} />}
                            >
                                More Wagers
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </BetSlipMatchContext.Provider>
    );
};
