import { observer } from 'mobx-react';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { PlayoffStore } from '../../../stores/playoff-store';
import { PlayoffSection } from './playoff-section';
import { PlayoffGame } from './playoff-game';
import Box from '@mui/material/Box';
import { WinnersConsolationLadderImage } from './images/winners-consolation-ladder-image';

export const WinnersConsolationLadder = observer(() => {
    const [{ gameLeaguePlayoffData }] = useDependencies(PlayoffStore);

    return (
        <PlayoffSection
            title={"Winner's Consolation Ladder"}
            absoluteImage={
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        zIndex: -1,
                    }}
                >
                    <WinnersConsolationLadderImage />
                </Box>
            }
        >
            {(roundNumber) =>
                gameLeaguePlayoffData?.winnersConsolationLadder
                    .find((item) => item.roundNumber === roundNumber)
                    ?.games.map((game) => {
                        return <PlayoffGame key={game.gameId} game={game} />;
                    })
            }
        </PlayoffSection>
    );
});
