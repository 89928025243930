import { inject, injectable } from 'inversify';
import { makeAutoObservable, runInAction, when } from 'mobx';
import { LoadStatus } from 'shared/src/utils';
import { apiClient } from '../../common/api/api-client';
import { LeagueContentStore } from './league-content-store';
import { GameLeaguePlayoffModel } from '../../common/api/api';

@injectable()
export class PlayoffStore {
    @inject(LeagueContentStore)
    private readonly leagueContentStore!: LeagueContentStore;

    leagueId!: number;
    gameLeaguePlayoffData?: GameLeaguePlayoffModel;
    loadStatus = LoadStatus.None;

    constructor() {
        makeAutoObservable(this);
    }

    init = async () => {
        try {
            runInAction(() => {
                this.loadStatus = LoadStatus.Loading;
            });

            await when(() => !!this.leagueContentStore.league);
            this.leagueId = this.leagueContentStore.league!.id;

            const gameLeaguePlayoffData = await apiClient.playoff(
                this.leagueId
            );

            runInAction(() => {
                this.gameLeaguePlayoffData = gameLeaguePlayoffData;
                this.loadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
            });
        }
    };
}
