export const customPrimary = {
    25: '#F5F8FF',
    50: '#EDF0FC',
    100: '#DBE2FB',
    150: '#C9D4F8',
    200: '#B8C5F7',
    250: '#A5B7F0',
    300: '#97A8F1',
    350: '#849BE8',
    400: '#778AE9',
    450: '#697CE4',
    500: '#5B6CDE',
    550: '#4F5CDC',
    600: '#434CD7',
    650: '#323FC9',
    main: '#323FC9',
    700: '#2F31B8',
    750: '#2228A0',
    800: '#212181',
    850: '#18196A',
    900: '#101153',
    950: '#07073E',
};

export const customSecondary = {
    25: '#EAFDED',
    50: '#CEFCD5',
    100: '#A0F6AF',
    150: '#58F081',
    main: '#58F081',
    200: '#2BE378',
    250: '#18D46D',
    300: '#46C268',
    350: '#20B55E',
    400: '#39A456',
    450: '#35954E',
    500: '#2C8745',
    550: '#28793E',
    600: '#246B36',
    650: '#0D5F2E',
    700: '#195128',
    750: '#05451F',
    800: '#0E381A',
    850: '#0A2C13',
    900: '#04210B',
    950: '#011506',
};

export const customTertiary = {
    25: '#F7F8F8',
    50: '#EFF1F2',
    100: '#DFE3E6',
    150: '#D0D5D9',
    200: '#C0C7CC',
    250: '#B0BABF',
    300: '#A2ADB4',
    350: '#93A0A7',
    400: '#85939B',
    450: '#75868F',
    500: '#677982',
    550: '#5A6D78',
    600: '#4C616C',
    650: '#3F5662',
    700: '#324A56',
    750: '#2A4350',
    main: '#2A4350',
    800: '#1F333D',
    850: '#182831',
    900: '#101D23',
    950: '#081217',
};

export const customNeutral = {
    25: '#F8F8F9',
    50: '#F0F1F2',
    100: '#E2E2E5',
    150: '#D3D4D7',
    200: '#C5C6CB',
    250: '#B7B8BE',
    300: '#AAABB2',
    350: '#9C9DA5',
    400: '#8F9099',
    450: '#82838D',
    500: '#757681',
    550: '#696A76',
    600: '#5C5D6A',
    650: '#50515F',
    700: '#454654',
    750: '#393B49',
    800: '#2E2F3E',
    850: '#242534',
    900: '#191A2A',
    950: '#060615',
    main: '#060615',
};

export const customNegative = {
    25: '#FCF6F6',
    50: '#F8EFEF',
    100: '#F1DEDC',
    150: '#E9CECC',
    200: '#E1BFBD',
    250: '#DAAEAB',
    300: '#D29F9C',
    350: '#C8908D',
    400: '#C1807C',
    450: '#B7706D',
    500: '#AD615E',
    550: '#A6514F',
    600: '#993D3D',
    main: '#993D3D',
    650: '#8B3737',
    700: '#792E2E',
    750: '#672626',
    800: '#551F1F',
    850: '#441717',
    900: '#331011',
    950: '#240707',
};
