import { Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { IBetSlipOdd, BetSlipStore } from '../stores/bet-slip-store';
import { observer } from 'mobx-react-lite';
import { formatAmericanOdd } from '../../common/utils/format-american-odd';
import { XCircle, Warning } from '@phosphor-icons/react';
import { customNegative } from '../../redesigned-modules/root/components/theme-provider/colors';
import { IBetSlipProps } from './bet-slip';
import { LiveOddsStore } from '../../common/stores/live-odds-store';
import { NumberChangeIndicator } from '../../common/components/connected-odd/number-change-indicator';
import Box from '@mui/material/Box';
import { disabledOddOpacity } from '../../common/utils/ui';

interface BetSlipOddProps {
    betSlipOdd: IBetSlipOdd;
    onMatchClick: IBetSlipProps['onMatchClick'];
}

export const BetSlipOdd: FC<BetSlipOddProps> = observer(
    ({ betSlipOdd, onMatchClick }) => {
        const [{ conflicts, selectOdd }, { liveOdds, disabledOdds }] =
            useDependencies(BetSlipStore, LiveOddsStore);

        const { betSlipName, betType, param } = betSlipOdd.odd;

        const liveOdd = liveOdds.get(betSlipOdd.odd.id)!;
        const isDisabled = disabledOdds.has(betSlipOdd.odd.id);

        const { americanStyleOdd = 0 } = liveOdd;

        const { id: matchId, teams } = betSlipOdd.matchInfo;

        const hasConflict = conflicts.has(betSlipOdd.odd.id);

        const theme = useTheme();

        return (
            <Stack spacing={2} direction={'row'}>
                <Stack
                    flexGrow={1}
                    spacing={0.5}
                    sx={{ opacity: isDisabled ? disabledOddOpacity : 1 }}
                >
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Stack direction={'row'} gap={2} alignItems={'center'}>
                            {!!betSlipName && (
                                <Typography fontWeight={700}>
                                    {betSlipName}
                                </Typography>
                            )}
                            <Typography fontWeight={700}>{param}</Typography>
                        </Stack>
                        <Stack
                            direction={'row'}
                            spacing={1}
                            alignItems={'center'}
                        >
                            <NumberChangeIndicator value={americanStyleOdd} />
                            <Typography fontWeight={600} color={'secondary'}>
                                {formatAmericanOdd(americanStyleOdd)}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Typography fontSize={12} color={'textSecondary'}>
                        {betType.name}
                    </Typography>
                    <Typography
                        variant="body1"
                        color={'textSecondary'}
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            onMatchClick(matchId);
                        }}
                    >
                        {teams[0]} @ {teams[1]}
                    </Typography>
                </Stack>
                <Stack justifyContent={'space-between'} alignItems={'center'}>
                    <XCircle
                        size={24}
                        color={theme.palette.text.secondary}
                        onClick={() => selectOdd(betSlipOdd)}
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                    {hasConflict && (
                        <Box
                            sx={{
                                opacity: isDisabled ? disabledOddOpacity : 1,
                            }}
                        >
                            <Warning
                                weight={'fill'}
                                size={24}
                                color={customNegative.main}
                            />
                        </Box>
                    )}
                </Stack>
            </Stack>
        );
    }
);
