import Box from '@mui/material/Box';
import { FC, Fragment, PropsWithChildren } from 'react';
import AppBackgroundImage from '../../../../../assets/redesign/images/app-background.png';
import { Header } from '../header/header';
import { Footer } from '../footer/footer';
import { getAppBarHeight } from '../../../../common/utils/ui';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { AppStore } from '../../../../common/stores/app-store';

export const LayoutAuthenticated: FC<PropsWithChildren> = observer(
    ({ children }) => {
        const [{ headerHeight }] = useDependencies(AppStore);

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    pt: `${headerHeight}px`,
                }}
            >
                <Header />
                <Box
                    component="main"
                    sx={{
                        flex: '1 0 auto',
                        minHeight: `calc(100vh - ${headerHeight}px)`,
                        pt: 2,
                        mb: 2,
                    }}
                >
                    {children}
                </Box>
                <Footer />
            </Box>
        );
    }
);
