import { observer } from 'mobx-react';
import { WithAnnouncements } from '../../../../accouncements/components/with-announcements/with-announcements';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { LobbyPage } from '../../../../lobby/components/lobby-page/lobby-page';
import { asParentPath } from '../../../../common/utils/as-parent-path';
import { WithBettingHub } from '../../../../root/components/with-betting-hub';
import { MyGamesPage } from '../../../../my-games/components/my-games-page';
import { HistoryPage } from '../../../../history/components/history-page/history-page';
import { ProfilePage } from '../../../../profile/components/profile-page';
import { FriendsPage } from '../../../../friends/components/friends-page';
import { SlatePage } from '../../../../slate/components/slate-page';
import { LeaguesPage } from '../../../../leagues/components/leagues-page';
import { WithCommsHub } from '../../../../root/components/with-comms-hub';
import { LayoutAuthenticated } from '../layout/layout-authenticated';
import Contact from '../../../../../pages/Contact';
import { TempThemeProvider } from '../theme-provider/theme-provider';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { NotificationsStore } from '../../../../common/stores/notifications-store';
import { useEffect } from 'react';

export const AppAuthenticated = observer(() => {
    const [{ notify }] = useDependencies(NotificationsStore);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const clearSuccessKey = () => {
            searchParams.delete('success');
            setSearchParams(searchParams);
        };

        if (searchParams.get('success') === 'true') {
            clearSuccessKey();
            notify({ severity: 'success', children: 'Transaction succeeded!' });
        } else if (searchParams.get('success') === 'false') {
            clearSuccessKey();
            notify({ severity: 'error', children: 'Transaction failed!' });
        }
    }, [notify, searchParams, setSearchParams]);

    return (
        <LayoutAuthenticated>
            <WithCommsHub>
                <WithAnnouncements>
                    <Routes>
                        {/*common*/}
                        <Route path="contacts" element={<Contact />} />

                        <Route
                            path="lobby"
                            element={
                                <TempThemeProvider>
                                    <LobbyPage />
                                </TempThemeProvider>
                            }
                        />
                        <Route
                            path={asParentPath('my-games')}
                            element={
                                <WithBettingHub>
                                    <MyGamesPage />
                                </WithBettingHub>
                            }
                        />
                        <Route path="history" element={<HistoryPage />} />
                        <Route
                            path={asParentPath('profile')}
                            element={<ProfilePage />}
                        />
                        <Route path="friends" element={<FriendsPage />} />
                        <Route
                            path={asParentPath('slate')}
                            element={<SlatePage />}
                        />
                        <Route
                            path={asParentPath('leagues')}
                            element={<LeaguesPage />}
                        />
                        <Route
                            path="*"
                            element={<Navigate to={'lobby'} replace />}
                        />
                    </Routes>
                </WithAnnouncements>
            </WithCommsHub>
        </LayoutAuthenticated>
    );
});
