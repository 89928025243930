import { FC } from 'react';
import { TeamModel } from '../../api/api';
import { Avatar, Box, Typography } from '@mui/material';

interface ITeamInfoProps {
    team: TeamModel;
}

export const TeamInfo: FC<ITeamInfoProps> = ({ team }) => {
    return (
        <Box display={'flex'} gap={1} alignItems={'center'}>
            <Avatar
                sx={{
                    '& img': {
                        objectFit: 'unset',
                    },
                }}
                src={team.logoUrl}
            />
            <Typography fontWeight={700}>{team.name}</Typography>
        </Box>
    );
};
