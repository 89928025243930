import { FC, ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Box from '@mui/material/Box';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { AppStore } from '../../common/stores/app-store';
import Drawer from '@mui/material/Drawer';
import { customNeutral } from '../../redesigned-modules/root/components/theme-provider/colors';

interface ISwipeableBetSlipContainerProps {
    children: (isOpen: boolean, toggle: () => void) => ReactNode;
}

export const SwipeableBetSlipContainer: FC<ISwipeableBetSlipContainerProps> =
    observer(({ children }) => {
        const [{ setIsMobileBetSlipVisible }] = useDependencies(AppStore);
        const [isOpen, setIsOpen] = useState(false);

        const open = () => setIsOpen(true);
        const close = () => setIsOpen(false);
        const toggle = () => {
            isOpen ? close() : open();
        };

        const drawerBleeding = isOpen ? 0 : 80;

        useEffect(() => {
            setIsMobileBetSlipVisible(true);

            return () => setIsMobileBetSlipVisible(false);
        }, []);

        return (
            <Drawer
                sx={{
                    '& .MuiPaper-root': {
                        height: isOpen
                            ? `calc(100% - ${drawerBleeding}px)`
                            : 'unset',
                        overflow: 'visible',
                        pointerEvents: 'unset !important',
                    },
                }}
                anchor="bottom"
                open={isOpen}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                        borderTopLeftRadius: isOpen ? 0 : 8,
                        borderTopRightRadius: isOpen ? 0 : 8,
                        overflow: 'hidden',
                        boxShadow: `0px 0px 10px 0px ${customNeutral[500]}`,
                    }}
                >
                    <Box sx={{ height: '100dvh' }}>
                        {children(isOpen, toggle)}
                    </Box>
                </Box>
            </Drawer>
        );
    });
