import moment from 'moment';
import { EMAIL_REGEX, ONLY_NUMBERS_REGEX, REQUIRED_FIELD } from './constants';
import { MakeOddBet } from '../pages/Slate/types';
import { format } from 'node:url';

export function validatePhoneNumber(phoneNumber?: string) {
    if (!phoneNumber) return;

    if (phoneNumber.length < 9) {
        return 'Phone number length must be at least 9 characters';
    }
    if (phoneNumber.length > 20) {
        return 'Phone number length must be less than 20 characters';
    }
    if (!ONLY_NUMBERS_REGEX.test(phoneNumber)) {
        return 'Phone number should include only numbers';
    }
}

export function validateEmail(email?: string) {
    if (!email) return;

    if (!EMAIL_REGEX.test(email)) {
        return 'Not a valid email address';
    }
}

const getDaysInMonth = (month: number) => {
    const year = new Date().getFullYear();
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days.length;
};

const getMonthDaysCount = (start: number, end: number) => {
    let month = start;
    let counter = 0;
    while (month < end) {
        counter = counter + getDaysInMonth(month);
        month = month + 1;
    }
    return counter;
};

export function filterRequiredMessages(errors: { [key: string]: string }) {
    const newErrors = { ...errors };
    Object.keys(newErrors).forEach((key) => {
        if (newErrors[key] === REQUIRED_FIELD) {
            delete newErrors[key];
        }
    });
    return newErrors;
}

export function getTimeDiffText(
    startDate: Date,
    endDate: Date,
    defaultText: string
) {
    const end = moment([
        startDate.getUTCFullYear(),
        startDate.getUTCMonth(),
        startDate.getUTCDate(),
    ]);
    const start = moment([
        endDate.getUTCFullYear(),
        endDate.getUTCMonth(),
        endDate.getUTCDate(),
    ]);

    const days = end.diff(start, 'days');
    const months = end.diff(start, 'months');

    const timeleft = startDate.getTime() - endDate.getTime();

    const hours = Math.floor((timeleft % (3600 * 24)) / 3600);
    const minutes = Math.floor((timeleft % 3600) / 60);

    if (days < 0 || hours < 0 || minutes < 0) {
        return defaultText;
    }

    if (months > 0) {
        const monthDayCount = getMonthDaysCount(
            endDate.getMonth(),
            startDate.getMonth()
        );

        return (
            `${months}m` +
            (days && days - monthDayCount > 0
                ? ` ${days - monthDayCount}d`
                : '')
        );
    }
    if (days) {
        return `${days}d` + (hours ? ` ${hours}h` : '');
    }

    if (hours) {
        return `${hours}h` + (minutes ? ` ${minutes}m` : '');
    }

    if (minutes) {
        return `${minutes}m`;
    }

    return '< 1m';
}

export const formatDateWithName = (
    initialDate: string | Date,
    excludeTime?: boolean,
    format = 'MM/DD'
) => {
    const date = new Date(initialDate);
    const time = getTime(initialDate);
    const timeString = ` ${time}`;
    let day;
    if (isToday(date)) {
        day = 'Today';
    } else if (isTomorrow(date)) {
        day = 'Tomorrow';
    } else if (isInAWeek(date)) {
        day = getDayName(date).slice(0, 3);
    } else {
        day = moment(date).format(format);
    }

    return day + (excludeTime ? '' : timeString);
};

export const formatDailyDate = (date: Date) => {
    if (isToday(date)) return 'Today';
    return moment(date).format('dddd, MMM D');
};

export function getTime(initialDate: string | Date) {
    const date = new Date(initialDate);
    return date.toLocaleString('en-US', { hour: 'numeric', hour12: true });
}

function getDayName(date = new Date(), locale = 'en-US') {
    return date.toLocaleDateString(locale, { weekday: 'long' });
}

function isInAWeek(date: Date) {
    const today = new Date();
    const diff = date.getTime() - today.getTime();
    const hoursInAWeek = 1000 * 60 * 60 * 24 * 7;
    return diff > 0 && diff < hoursInAWeek;
}

function isToday(date: Date) {
    const today = new Date();
    return today.toDateString() === date.toDateString();
}

function isTomorrow(date: Date) {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return tomorrow.toDateString() === date.toDateString();
}

export const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max);
};

export const getLogoFromTeamName = (name: string) => {
    const splittedParts = name.split(' ');
    return splittedParts.reduce((acc, string) => {
        acc = `${acc}${string.substring(0, 1)}`;
        return acc;
    }, '');
};

export const getNotigicationTime = (date: string) => {
    const date_ = new Date(date);
    const NOW = new Date();
    const end = moment([
        NOW.getUTCFullYear(),
        NOW.getUTCMonth(),
        NOW.getUTCDate(),
    ]);
    const start = moment([
        date_.getUTCFullYear(),
        date_.getUTCMonth(),
        date_.getUTCDate(),
    ]);

    const days = end.diff(start, 'days');

    const week = end.diff(start, 'week');
    const minutes = end.diff(start, 'minutes');
    const hours = end.diff(start, 'hours');

    if (week > 0) {
        return formatDateWithName(date, true);
    }
    if (days > 0) {
        return `${days} days ago`;
    }
    if (hours > 0) {
        return `${hours} hours ago`;
    }
    if (minutes > 0) {
        return `${minutes} minutes ago`;
    }
    return 'now';
};

export function isSmallScreen() {
    return document.body.clientWidth < 600;
}

export const getCellTextColor = (isPositive: boolean) => {
    return '#2B5FD9';
};

export const toAmerican = (coefficient: number): number => {
    if (coefficient <= 1) {
        throw new Error('Coefficient must be greater than 1.');
    }

    const value =
        coefficient >= 2 ? (coefficient - 1) * 100 : -100 / (coefficient - 1);
    const castedValue = Math.round(value);

    return castedValue;
};

export const calculateTotalCoefficient = (bets: MakeOddBet[]): number => {
    const totalCoefficient = bets.reduce(
        (total, bet) => total * bet.odd.coefficient,
        1
    );
    return totalCoefficient;
};

export const areAllItemsTheSame = (arr: string[]) => {
    return arr.every((item, _index, array) => item === array[0]);
};

export const setLocalTime = (initialDate: string | Date) => {
    const date =
        typeof initialDate === 'string'
            ? new Date(initialDate + 'Z')
            : new Date(initialDate);

    return moment(date).format('h:mm A').toLocaleString();
};
