import { Matchup } from '../components/content/matchup/matchup';
import { Standings } from '../components/content/standings/standings';
import { TreeStructure, Table, Coin, Handshake } from '@phosphor-icons/react';
import { Playoff } from '../components/content/playoff/playoff';
import { CurrentLeagueGame } from '../components/content/current-league-game-redesigned/current-league-game';

export const LeagueContentRoutes = [
    {
        name: 'Bet',
        pathname: 'bet',
        Component: CurrentLeagueGame,
        icon: <Coin size={24} />,
    },
    {
        name: 'Matchup',
        pathname: 'matchup',
        Component: Matchup,
        icon: <Handshake size={24} />,
    },
    {
        name: 'Standings',
        pathname: 'standings',
        Component: Standings,
        icon: <Table size={24} />,
    },
    {
        name: 'Playoff',
        pathname: 'playoff',
        Component: Playoff,
        icon: (
            <TreeStructure style={{ transform: 'rotate(180deg)' }} size={24} />
        ),
    },
];
