import { FC } from 'react';
import { MatchOddModel, MatchWithOddsModel, TeamModel } from '../../api/api';
import { Box, CardActionArea, Grid2 as Grid, Stack } from '@mui/material';
import Card from '@mui/material/Card';
import { ConnectedOdd } from '../connected-odd/connected-odd';
import { TeamInfo } from './team-info';

export interface MatchOverviewRowProps {
    currentTeam?: TeamModel;
    currentOdds: MatchOddModel[];
    matchOverview: MatchWithOddsModel;
    onMatchClick: (matchOverview: MatchWithOddsModel) => void;
}

export const MatchOverviewRow: FC<MatchOverviewRowProps> = ({
    matchOverview,
    currentTeam,
    currentOdds,
    onMatchClick,
}) => {
    return (
        <Grid container spacing={0.5}>
            <Grid size={5}>
                <Card
                    sx={{
                        height: '100%',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        minHeight: 64,
                        background: 'transparent',
                        borderRadius: 1,
                    }}
                    onClick={() => onMatchClick(matchOverview)}
                >
                    <CardActionArea sx={{ height: '100%' }}>
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                p: 1,
                                gap: 3,
                            }}
                        >
                            {currentTeam ? (
                                <TeamInfo team={currentTeam} />
                            ) : (
                                <Stack spacing={1}>
                                    <TeamInfo
                                        team={matchOverview.match.teams![0]}
                                    />
                                    <TeamInfo
                                        team={matchOverview.match.teams![1]}
                                    />
                                </Stack>
                            )}
                        </Box>
                    </CardActionArea>
                </Card>
            </Grid>
            <Grid size={7} container spacing={0.5}>
                {currentOdds.map((odd, index) => {
                    return (
                        <Grid
                            key={index}
                            size={Math.round(12 / currentOdds.length)}
                        >
                            {/* odds may be null */}
                            {odd.americanStyleOdd !== undefined && (
                                <Card
                                    sx={{
                                        height: '100%',
                                        boxShadow: 'none',
                                        background: 'transparent',
                                    }}
                                >
                                    <ConnectedOdd odd={odd} />
                                </Card>
                            )}
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};
