import { Stack, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { IBetSlipOdd, BetSlipStore } from '../stores/bet-slip-store';
import { observer } from 'mobx-react-lite';
import { formatAmericanOdd } from '../../common/utils/format-american-odd';
import { XCircle, Warning } from '@phosphor-icons/react';
import { customNegative } from '../../redesigned-modules/root/components/theme-provider/colors';
import { IBetSlipProps } from './bet-slip';
import { LiveOddsStore } from '../../common/stores/live-odds-store';
import { OddChangeIndicator } from '../../common/components/connected-odd/odd-change-indicator';

interface BetSlipOddProps {
    betSlipOdd: IBetSlipOdd;
    onMatchClick: IBetSlipProps['onMatchClick'];
}

export const BetSlipOdd: FC<BetSlipOddProps> = observer(
    ({ betSlipOdd, onMatchClick }) => {
        const [{ conflicts, selectOdd }, { liveOdds }] = useDependencies(
            BetSlipStore,
            LiveOddsStore
        );

        const { betSlipName, betType, param } = betSlipOdd.odd;

        const liveOdd = liveOdds.get(betSlipOdd.odd.id)!;
        const { americanStyleOdd = 0 } = liveOdd;

        const { id: matchId, teams } = betSlipOdd.matchInfo;

        const hasConflict = conflicts.has(betSlipOdd.odd.id);

        const theme = useTheme();

        return (
            <Stack spacing={0.5}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Stack direction={'row'} gap={2} alignItems={'center'}>
                        {!!betSlipName && (
                            <Typography fontWeight={700}>
                                {betSlipName}
                            </Typography>
                        )}
                        <Typography fontWeight={700}>{param}</Typography>
                    </Stack>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <OddChangeIndicator value={americanStyleOdd} />
                        <Typography fontWeight={600} color={'secondary'}>
                            {formatAmericanOdd(americanStyleOdd)}
                        </Typography>
                        <XCircle
                            size={24}
                            color={theme.palette.text.secondary}
                            onClick={() => selectOdd(betSlipOdd)}
                            style={{
                                cursor: 'pointer',
                            }}
                        />
                    </Stack>
                </Stack>
                <Typography fontSize={12} color={'textSecondary'}>
                    {betType.name}
                </Typography>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Typography
                        variant="body1"
                        color={'textSecondary'}
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            onMatchClick(matchId);
                        }}
                    >
                        {teams[0]} @ {teams[1]}
                    </Typography>
                    {hasConflict && (
                        <Warning
                            weight={'fill'}
                            size={24}
                            color={customNegative[600]}
                        />
                    )}
                </Stack>
            </Stack>
        );
    }
);
