import {
    alpha,
    CardActionArea,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { observer } from 'mobx-react-lite';
import { FC, useContext } from 'react';
import {
    IBetSlipOdd,
    BetSlipStore,
} from '../../../bet-slip/stores/bet-slip-store';
import { MatchOddModel } from '../../api/api';
import { BetSlipMatchContext } from '../../contexts/bet-slip-match-context';
import { LiveOddsStore } from '../../stores/live-odds-store';
import { formatAmericanOdd } from '../../utils/format-american-odd';
import { useDependencies } from '../../utils/ioc/use-dependencies';
import { NumberChangeIndicator } from './number-change-indicator';
import { CaretDown, CaretUp } from '@phosphor-icons/react';
import {
    customNegative,
    customPositive,
} from '../../../redesigned-modules/root/components/theme-provider/colors';
import { disabledOddOpacity } from '../../utils/ui';

interface ConnectedOddProps {
    odd: MatchOddModel;
}

const numberIndicatorPadding = 3;

export const ConnectedOdd: FC<ConnectedOddProps> = observer(({ odd }) => {
    const [{ activeOdds, conflicts, selectOdd }, { liveOdds, disabledOdds }] =
        useDependencies(BetSlipStore, LiveOddsStore);

    const { id, param } = odd;

    const liveOdd = liveOdds.get(odd.id)!;
    const isDisabled = disabledOdds.has(odd.id);

    const { americanStyleOdd = 0, coefficient } = liveOdd;

    const isActive = activeOdds.has(id);
    const isError = conflicts.has(id);
    const theme = useTheme();

    const bgColor = isError
        ? alpha(theme.palette.error.main, 0.5)
        : isActive
          ? alpha(theme.palette.secondary.main, 0.3)
          : alpha('#ffffff', 0.05);

    const betSlipMatchInfo = useContext(BetSlipMatchContext);

    const betSlipOdd: IBetSlipOdd = {
        odd: {
            ...odd,
            americanStyleOdd,
            coefficient,
        },
        matchInfo: betSlipMatchInfo,
    };

    return (
        <Card
            sx={{
                height: '100%',
                background: bgColor,
                boxShadow: 'none',
                position: 'relative',
                borderRadius: 0,
                opacity: isDisabled ? disabledOddOpacity : 1,
            }}
        >
            <CardActionArea
                sx={{ height: '100%' }}
                disabled={isDisabled}
                onClick={() => {
                    selectOdd(betSlipOdd);
                }}
            >
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: {
                            xs: 'center',
                            sm: 'space-between',
                        },
                        flexDirection: {
                            xs: 'column',
                            sm: 'row',
                        },
                        px: {
                            xs: 1,
                            sm: 2,
                        },
                        py: 0.5,
                    }}
                >
                    {!!param && (
                        <Typography
                            color={'white'}
                            sx={{ mx: 'auto' }}
                            textAlign={'center'}
                            fontWeight={600}
                        >
                            {param}
                        </Typography>
                    )}
                    <Typography
                        color={isError ? 'white' : 'secondary'}
                        sx={{ mx: 'auto' }}
                        textAlign={'center'}
                        fontWeight={600}
                    >
                        {formatAmericanOdd(americanStyleOdd!)}
                    </Typography>
                    <NumberChangeIndicator
                        value={americanStyleOdd}
                        upIcon={
                            <CaretUp
                                style={{
                                    position: 'absolute',
                                    right: numberIndicatorPadding,
                                    top: numberIndicatorPadding,
                                    transform: 'rotate(45deg)',
                                }}
                                size={16}
                                weight={'fill'}
                                color={customPositive.main}
                            />
                        }
                        downIcon={
                            <CaretDown
                                style={{
                                    position: 'absolute',
                                    right: numberIndicatorPadding,
                                    bottom: numberIndicatorPadding,
                                    transform: 'rotate(-45deg)',
                                }}
                                size={16}
                                weight={'fill'}
                                color={customNegative.main}
                            />
                        }
                    />
                </Box>
            </CardActionArea>
        </Card>
    );
});
