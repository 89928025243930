import { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { provide } from '../../../../common/utils/ioc/provide';
import { CurrentLeagueGameStore } from '../../../stores/current-league-game-store';
import { BetSlipStore } from '../../../../bet-slip/stores/bet-slip-store';
import {
    Navigate,
    Route,
    Routes,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { LoadStatus } from '../../../../common/enums/load-status';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { LeagueContentStore } from '../../../stores/league-content-store';
import { SportLeagues } from '../../../../redesigned-modules/common/components/sport-leagues/sport-leagues';
import { CommonBettingSection } from '../../../../redesigned-modules/common/components/common-betting-section/common-betting-section';
import { CurrentGameContent } from './current-game-content';
import { BetSlip } from '../../../../bet-slip/redesigned-components/bet-slip';
import { LiveOddsStore } from '../../../../common/stores/live-odds-store';
import { WithBettingHub } from '../../../../root/components/with-betting-hub';

const CurrentLeagueGameMain = provide([
    CurrentLeagueGameStore,
    BetSlipStore,
    LiveOddsStore,
])(
    observer(() => {
        const [
            {
                game,
                sportLeagueId,
                sportLeaguesLoadStatus,
                init,
                selectSportLeague,
                selectMatch,
                sports,
            },
            { initBetSlip, setMinBetAmount },
            { currentGameId },
        ] = useDependencies(
            CurrentLeagueGameStore,
            BetSlipStore,
            LeagueContentStore
        );

        const { sportLeagueId: sportLeagueIdParam, matchId: matchIdParam } =
            useParams();

        const navigate = useNavigate();

        useEffect(() => {
            if (currentGameId) {
                initBetSlip(currentGameId);
                init(currentGameId);
            }
        }, [currentGameId]);

        useEffect(() => {
            if (game) {
                setMinBetAmount(game.minBetAmount);
            }
        }, [game, setMinBetAmount]);

        useEffect(() => {
            // TODO: add number validation
            if (currentGameId && sportLeagueIdParam) {
                selectSportLeague(+sportLeagueIdParam);
            }
        }, [currentGameId, selectSportLeague, sportLeagueIdParam]);

        useEffect(() => {
            // TODO: add number validation
            selectMatch(matchIdParam ? +matchIdParam : undefined);
        }, [matchIdParam, selectMatch]);

        const handleSportLeagueChange = useCallback(
            (selectedId: number, replace?: boolean) => {
                if (selectedId !== sportLeagueId) {
                    navigate(`../${selectedId}`, { replace });
                }
            },
            // TODO: USHADIR to sportLeagueId
            [navigate, sportLeagueId]
        );

        useEffect(() => {
            if (
                sportLeaguesLoadStatus === LoadStatus.Ok &&
                sportLeagueIdParam === undefined
            ) {
                const firstValidSport = sports.find(
                    (sport) => !!sport.leagues?.length
                );

                if (firstValidSport) {
                    const firstLeague = firstValidSport.leagues![0];
                    handleSportLeagueChange(firstLeague.id, true);
                }
            }
        }, [
            handleSportLeagueChange,
            sportLeagueIdParam,
            sportLeaguesLoadStatus,
            sports,
        ]);

        const handleBetSlipMatchClick = (matchId: number) => {
            navigate(`../${sportLeagueId}/${matchId}`);
        };

        return (
            <CommonBettingSection
                sportLeagues={
                    <SportLeagues
                        onChange={handleSportLeagueChange}
                        sportLeagues={sports}
                        activeSportLeagueId={sportLeagueId}
                    />
                }
                content={
                    <CurrentGameContent
                        activeMatchId={matchIdParam ? +matchIdParam : undefined}
                    />
                }
                betSlip={<BetSlip onMatchClick={handleBetSlipMatchClick} />}
            />
        );
    })
);

export const CurrentLeagueGame = () => {
    return (
        <WithBettingHub>
            <Routes>
                <Route
                    path={':sportLeagueId?/:matchId?'}
                    element={<CurrentLeagueGameMain />}
                />
                <Route path={'*'} element={<Navigate to={''} replace />} />
            </Routes>
        </WithBettingHub>
    );
};
