import { FC } from 'react';
import { observer } from 'mobx-react';
import { LeagueTitleModel, SportFilterModel } from '../../../../common/api/api';
import { Stack } from '@mui/material';
import { SportLeague } from './sport-league';

interface ISportLeaguesProps {
    activeSportLeagueId?: LeagueTitleModel['id'];
    onChange: (sportLeagueId: LeagueTitleModel['id']) => void;
    sportLeagues: SportFilterModel[];
}

export const SportLeagues: FC<ISportLeaguesProps> = observer(
    ({ sportLeagues, activeSportLeagueId, onChange }) => {
        return (
            <Stack direction={'row'} gap={2} flexWrap={'wrap'}>
                {sportLeagues.map(({ leagues }) =>
                    leagues.map((sportLeague) => (
                        <SportLeague
                            onClick={() => onChange(sportLeague.id)}
                            isActive={sportLeague.id === activeSportLeagueId}
                            key={sportLeague.id}
                            sportLeague={sportLeague}
                        />
                    ))
                )}
            </Stack>
        );
    }
);
