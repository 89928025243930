import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface BackButtonProps {
    label: string;
    onClick: () => void;
    isBack?: boolean;
}

export const BackButton: FC<BackButtonProps> = observer(
    ({ label, onClick, isBack = true }) => {
        return (
            <Button
                variant={'contained'}
                startIcon={<ArrowBackIcon />}
                onClick={onClick}
            >
                {isBack ? 'Back' : 'Go'} to {label}
            </Button>
        );
    }
);
